
import { defineComponent, PropType } from "vue";
import { OnCallUser } from "@/components/on-call/OnCallWidget.vue";
import XIcon from "@/components/Icons/XIcon.vue";
export default defineComponent({
  name: "on-call-user",
  components: { XIcon },
  emits: ["remove-on-call"],
  props: {
    data: {
      type: Object as PropType<OnCallUser>,
      required: true
    }
  }
});
