import store from "@/store";
const DocMenuConfig: object = [
  {
    heading: "Menu",
    route: "/users",
    pages: [
      {
        heading: "My Dashboard",
        route: "/my-dashboard",
        permission: "my_dashboard_access",
        svgIcon: "/media/icons/duotone/Design/Substract.svg"
      },
      {
        heading: "Dashboard",
        route: "/dashboard",
        svgIcon: "/media/icons/duotone/Design/PenAndRuller.svg",
        permission: "dashboard_access"
      },
      {
        sectionTitle: "Statistics",
        svgIcon: "/media/icons/duotone/Design/PenAndRuller.svg",
        permission: "can_view_statistics",
        sub: [
          {
            heading: "General",
            route: "/insights",
            permission: "can_view_statistics"
          },
          {
            heading: "Workers",
            route: "/insights/workers",
            permission: "can_view_statistics"
          },
          {
            heading: "Earnings",
            route: "/insights/earnings",
            permission: "can_view_earning_statistics"
          }
        ]
      },
      {
        heading: "Chat",
        route: "/live-chat",
        svgIcon: "/media/icons/duotone/Communication/Group-chat.svg",
        permission: "has_live_chat"
      },
      {
        heading: "Calendar",
        route: "/calendar",
        svgIcon: "/media/icons/duotone/Interface/Calendar.svg",
        permission: "admin_calendar_access"
      },
      {
        heading: "My Calendar",
        route: "/calendar",
        svgIcon: "/media/icons/duotone/Interface/Calendar.svg",
        permission: "my_calendar_access"
      },
      {
        heading: "My Documents",
        route: `/users/me/my-documents`,
        svgIcon: "/media/icons/duotone/Design/PenAndRuller.svg",
        permission: "document_access"
      },
      {
        heading: "My Skills",
        route: "/users/me/my-skills",
        svgIcon: "/media/icons/duotone/Interface/Calendar.svg",
        permission: "my_skills_access"
      },
      {
        heading: "Clients",
        route: "/clients",
        svgIcon: "/media/icons/duotone/Clothes/Briefcase.svg",
        permission: "company_access"
      },
      {
        heading: "Approvals",
        route: "/approvals",
        svgIcon: "/media/icons/duotone/Navigation/Check.svg",
        permission: "approval_access"
      },
      {
        sectionTitle: "Payments",
        svgIcon: "/media/icons/duotone/Text/Bullet-list.svg",
        permission: "payment_access",
        sub: [
          {
            heading: "Salary reports",
            route: "/salaryreports",
            permission: "salary_access"
          }
        ]
      },
      {
        sectionTitle: "Job quotes",
        svgIcon: "/media/icons/duotone/Interface/Cog.svg",
        permission: "payment_access",
        sub: [
          {
            heading: "Quotes",
            route: "/job-quotes",
            permission: "payment_access"
          },
          {
            heading: "Create quote",
            permission: "payment_access",
            route: "/create-quote"
          }
        ]
      },
      {
        heading: "Users",
        route: "/users",
        svgIcon: "/media/icons/duotone/General/User.svg",
        permission: "user_management_access"
      },
      {
        heading: "Applicants",
        route: "/applicants",
        svgIcon: "/media/icons/duotone/General/User.svg",
        permission: "can_view_applicants_list"
      },
      {
        heading: "Job Templates",
        route: "/jobs/templates",
        svgIcon: "/media/icons/duotone/Devices/Keyboard.svg",
        permission: "job_template_access"
      },
      // {
      //   heading: "Equipment",
      //   route: "/equipment",
      //   svgIcon: "/media/icons/duotone/Devices/Camera.svg",
      //   fontIcon: "bi-person",
      //   permission: "equipment_view"
      // },
      {
        heading: "Venues",
        route: "/venues",
        svgIcon: "/media/icons/duotone/Home/Building.svg",
        permission: "venue_access"
      },
      {
        heading: "Freelancers",
        route: "/freelancers",
        svgIcon: "/media/icons/duotone/General/User.svg",
        permission: "freelancer_access"
      },
      {
        sectionTitle: "Settings",
        svgIcon: "/media/icons/duotone/Interface/Settings-02.svg",
        permission: "setting_access",
        sub: [
          {
            heading: "Skills",
            route: "/skills",
            permission: "settings_skill_access"
          },
          {
            heading: "Equipment Categories",
            route: "/equipment-categories",
            permission: "equipment_category_access"
          },
          {
            heading: "Skill rates",
            route: "/worker-rates",
            permission: "can_manage_worker_rates"
          },
          {
            heading: "Levels",
            route: "/levels",
            permission: "level_access"
          },
          {
            heading: "Document Types",
            route: "/document-types",
            permission: "document_type_access"
          },
          {
            heading: "Dress Codes",
            route: "/dresscodes",
            permission: "dresscode_access"
          },
          {
            heading: "Permissions",
            route: "/permissions",
            permission: "permission_access"
          },
          {
            heading: "Roles",
            route: "/roles",
            permission: "role_access"
          }
        ]
      },
      {
        sectionTitle: "System",
        svgIcon: "/media/icons/duotone/Interface/Cog.svg",
        permission: "system_access",
        sub: [
          {
            heading: "Activity log",
            route: "/activity-log",
            permission: "can_view_all_activities"
          },
          {
            heading: "Branches",
            route: "/branches",
            permission: "branch_access"
          },
          {
            heading: "Countries",
            route: "/countries",
            permission: "country_access"
          },
          {
            heading: "Languages",
            route: "/languages",
            permission: "language_access"
          }
        ]
      },
      {
        heading: "Help",
        route: "/help",
        permission: "help_access",
        svgIcon: "/media/icons/duotone/Design/Substract.svg"
      },
      {
        sectionTitle: "Help",
        permission: "help_access",
        svgIcon: "/media/icons/duotone/Design/Substract.svg",
        sub: [
          {
            heading: "Help Categories",
            route: "/help-categories",
            permission: "help_category_access"
          }
        ]
      },
      {
        sectionTitle: "Data Protection",
        svgIcon: "/media/icons/duotone/Devices/Server.svg",
        permission: "data_protection_access",
        sub: [
          {
            heading: "Privacy Policy",
            route: "/privacy-policy",
            permission: "data_protection_access"
          },
          {
            heading: "Privacy Notice",
            route: "/privacy-notice",
            permission: "data_protection_access"
          }
        ]
      }
    ]
  }
];

export default DocMenuConfig;
