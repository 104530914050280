<template>
  <span v-if="approvalsCounter > 0" class="badge badge-square badge-primary ms-2 approvals-counter">{{ approvalsCounter }}</span>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "ApprovalsCounter",
  methods: {
    ...mapActions("DocumentsModule", [
      "fetchApprovals"
    ]),
  },
  computed: {
    ...mapGetters("DocumentsModule", [
      "approvalsCounter"
    ])
  },
  created() {
    this.fetchApprovals(1);
  }
});
</script>

<style scoped>
.approvals-counter {
  height: 1rem;
}
</style>