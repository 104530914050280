
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "x-icon",
  props: {
    fill: {
      type: String as PropType<string>,
      default: "#222B45"
    }
  }
});
