
import { useStore } from "vuex";
import { ref, defineComponent, computed, onMounted, onUnmounted } from "vue";
import SearchIcon from "@/components/Icons/SearchIcon.vue";
import ChatHistory from "@/components/live-chat/ChatHistory.vue";
import {
  ChatHistoryEntryObject,
  SelectChatEvent,
  WebSocketNewMessageEvent
} from "@/components/live-chat/ts/types";
import UnreadMessagesCounter from "@/components/live-chat/ui-fragments/UnreadMessagesCounter.vue";
import { eventBus } from "@/pusher.js";
import Swal from "sweetalert2";
import ChatWindow from "@/components/live-chat/ui-fragments/ChatWindow.vue";
export default defineComponent({
  name: "drawer-chat",
  components: {
    ChatWindow,
    ChatHistory,
    UnreadMessagesCounter,
    SearchIcon
  },
  setup: function() {
    const store = useStore();
    const isOpen = ref<boolean>(false);
    const selectedChat = ref<SelectChatEvent | null>(null);
    const drawerChatHistory = ref<InstanceType<typeof ChatHistory>>();
    const chats = computed<ChatHistoryEntryObject[]>(
      () => store.getters["LiveChatModule/getChatHistory"]
    );
    const chatDropdown = ref<HTMLElement | null>(null);

    function handleSelectChat(event: SelectChatEvent | null): void {
      selectedChat.value = event;
    }

    function handleDropdownHide(): void {
      handleSelectChat(null);
      isOpen.value = false;
    }

    onMounted((): void => {
      if (chatDropdown.value) {
        chatDropdown.value?.addEventListener(
          "hidden.bs.dropdown",
          handleDropdownHide
        );
      }
    });

    onUnmounted(() => {
      if (chatDropdown.value) {
        chatDropdown.value?.removeEventListener(
          "hidden.bs.dropdown",
          handleDropdownHide
        );
      }
    });

    eventBus.on("openAdminJobChat", (event: number) => {
      isOpen.value = true;
      drawerChatHistory.value?.setLoading(true);
      store
        .dispatch("LiveChatModule/fetchChatHistory")
        .then(() => {
          drawerChatHistory.value?.setLoading(false);
          const chat = chats.value.find(item => item.id == event);
          if (chat) {
            handleSelectChat({
              chatId: event,
              participants: chat.participants,
              userId: null,
              chatName: chat.name ? chat.name : "Group chat"
            });
          }
        })
        .catch(() => {
          drawerChatHistory.value?.setLoading(false);
        });
    });

    eventBus.on("NewChatMessage", (eventData: WebSocketNewMessageEvent) => {
      console.log(selectedChat.value, eventData);
      if (selectedChat.value?.chatId != eventData.message.chat_id) {
        const message =
          eventData.message.body.length > 50
            ? eventData.message.body.substring(0, 49) + "..."
            : eventData.message.body;
        Swal.fire({
          toast: true,
          icon: "info",
          text: `${eventData.sender.name} : ${message}`,
          timer: 3000,
          title: "New Message",
          position: "top-right",
          iconColor: "#289EF7",
          confirmButtonColor: "#289EF7",
          confirmButtonText: "View chat"
        }).then(result => {
          if (result.isConfirmed) {
            isOpen.value = true;
            handleSelectChat(null);
            drawerChatHistory.value?.setLoading(true);
            store
              .dispatch("LiveChatModule/fetchChatHistory")
              .then(() => {
                drawerChatHistory.value?.setLoading(false);
                const chat = chats.value.find(
                  item => item.id == eventData.message.chat_id
                );
                if (chat && chatDropdown.value) {
                  handleSelectChat({
                    chatId: eventData.message.chat_id,
                    participants: chat.is_group ? chat.participants : null,
                    userId: null,
                    chatName: chat.is_group
                      ? chat.name ?? ""
                      : eventData.sender.name
                  });
                  chatDropdown.value.click();
                  isOpen.value = true;
                }
              })
              .catch(() => {
                drawerChatHistory.value?.setLoading(false);
              });
          }
        });
      }
    });

    return {
      drawerChatHistory,
      handleSelectChat,
      selectedChat,
      chatDropdown,
      isOpen
    };
  }
});
