
import { defineComponent } from "vue";
import BackButton from "@/components/general/BackButton.vue";

export default defineComponent({
  name: "mobile-page-title",
  components: {
    BackButton
  },
  props: {
    breadcrumbs: Array,
    title: String
  },
  computed: {
    isVisible() {
      if (this.isNotChildOfUserRoute) return false;
      else return true;
    },
    isNotChildOfUserRoute() {
      if (this.$route.matched.some(({ name }) => name === "ViewUser")) return true;
      return false;
    }
  }
});
