import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex pt-4 w-100" }
const _hoisted_2 = { class: "pe-3" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "gap-1 d-flex flex-column w-100" }
const _hoisted_5 = { class: "d-flex w-100 align-items-center justify-content-between" }
const _hoisted_6 = { class: "fs-7 text-gray-100 fw-bold" }
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XIcon = _resolveComponent("XIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.data.photo,
        class: "mw-30px h-30px min-w-30px w-100 h-auto rounded-2"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.data.name), 1),
        (_ctx.$can('can_remove_on_call_user', 'all'))
          ? (_openBlock(), _createBlock(_component_XIcon, {
              key: 0,
              fill: "white",
              class: "cursor-pointer",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('remove-on-call', _ctx.data.id)))
            }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("a", {
        href: `tel:${_ctx.data.phone}`,
        class: "text-gray-500 fs-7 fw-normal"
      }, _toDisplayString(_ctx.data.phone), 9, _hoisted_7),
      _createElementVNode("a", {
        href: `mailto:${_ctx.data.email}`,
        class: "text-gray-500 fs-7 fw-normal"
      }, _toDisplayString(_ctx.data.email), 9, _hoisted_8)
    ])
  ]))
}