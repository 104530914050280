
import { defineComponent } from "vue";
import { mapGetters, useStore } from "vuex";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import DrawerChat from "@/components/live-chat/DrawerChat.vue";

export default defineComponent({
  name: "topbar",
  components: {
    DrawerChat,
    KTUserMenu
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser"
    })
  },
});
