
import IconArrowDown from "@/components/Icons/IconArrowDown.vue";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import OnCallUser from "@/components/on-call/OnCallUser.vue";
import { fireToast } from "@/core/helpers/swal";

export interface OnCallUser {
  email: string;
  id: number;
  name: string;
  photo: string;
  phone: string;
}

interface SearchUserSelectEvent {
  userId: number;
  value: string;
}

export default defineComponent({
  name: "on-call-widget",
  components: { OnCallUser, IconArrowDown },
  setup: function() {
    const store = useStore();
    const loading = ref<boolean>(false);
    const inputValue = ref("");
    const popoverRef = ref();
    const isCollapsed = ref<boolean>(false);

    const onCallUsers = computed<OnCallUser[]>(
      (): OnCallUser[] => store.getters["OnCallUsersModule/onCallUsers"]
    );

    function setLoading(state: boolean): void {
      loading.value = state;
    }

    function fetchOnCall(): void {
      store
        .dispatch("OnCallUsersModule/fetchOnCallUsers")
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }

    function handleSelect(event: SearchUserSelectEvent) {
      store
        .dispatch("OnCallUsersModule/addOnCallUser", event.userId)
        .then(() => {
          popoverRef.value.hide();
          inputValue.value = "";
          fetchOnCall();
          fireToast("On call added successfully", true);
        });
    }

    function setIsCollapsed(state: boolean): void {
      isCollapsed.value = state;
    }

    function attachListeners() {
      const element = document.getElementById("collapseOnCall");
      element?.addEventListener("hide.bs.collapse", () =>
        setIsCollapsed(false)
      );
      element?.addEventListener("show.bs.collapse", () => setIsCollapsed(true));
    }

    function searchUsers(query: string, callBack: (arg) => void) {
      store.dispatch("OnCallUsersModule/searchUsers", query).then(result => {
        callBack(
          result.map(item => {
            return {
              value: item.name,
              userId: item.id
            };
          })
        );
      });
    }

    onMounted((): void => {
      fetchOnCall();
      attachListeners();
    });

    function handleDeleteOnCall(id: number): void {
      store.dispatch("OnCallUsersModule/removeOnCallUser", id).then(() => {
        fetchOnCall();
        fireToast("On call removed successfully", true);
      });
    }

    return {
      handleDeleteOnCall,
      handleSelect,
      onCallUsers,
      searchUsers,
      isCollapsed,
      inputValue,
      popoverRef,
      loading
    };
  }
});
