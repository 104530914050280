
import { computed, defineComponent, onMounted } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "applicants-counter",
  setup() {
    const store = useStore();
    const count = computed<number>(
      () => store.getters["ApplicantsModule/count"]
    );
    onMounted(() => {
      store.dispatch("ApplicantsModule/getCount");
    });
    return {
      count
    };
  }
});
