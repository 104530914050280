
import { defineComponent } from "vue";
import KTTopbar from "@/layout/header/Topbar.vue";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay
} from "@/core/helpers/config";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String
  },
  components: {
    KTTopbar
  },
  setup() {
    console.log(process.env);
    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay
    };
  }
});
