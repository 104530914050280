import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68e76ca7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "bg-danger position-absolute text-white h-100 w-100 unread-message-counter"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.unreadMessages > 0)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(10 > _ctx.unreadMessages ? _ctx.unreadMessages : "9+"), 1))
    : _createCommentVNode("", true)
}