import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dacedd56"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "badge badge-square badge-primary ms-2 custom-height"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.count > 0)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.count), 1))
    : _createCommentVNode("", true)
}