
import { defineComponent } from "vue";
import { mapGetters, useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { useAbility } from "@casl/vue";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  computed: {
    ...mapGetters({
      currentUser: "currentUser"
    })
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const ability = useAbility();
    const signOut = () => {
      store.dispatch(Actions.LOGOUT).then(() =>
        router.push({ name: "sign-in" }).then(() => {
          const windowRef = window as any;
          if (windowRef.Echo) {
            delete windowRef.Echo;
            delete windowRef.Pusher;
          }
          ability.update([]);
        })
      );
    };

    return {
      signOut
    };
  }
});
