
import { defineComponent } from "vue";

export default defineComponent({
  name: "invite-a-friend-menu-item",
  setup() {
    function handleOpenModal() {
      const event = new Event("open_invite_friend_modal");
      const element = document.getElementById("app");
      element?.dispatchEvent(event);
    }
    return {
      handleOpenModal
    };
  }
});
