<template>
  <button type="button" class="btn btn-sm btn-primary float-end" @click="routerBack">
    <span class="fas fa-chevron-left me-3"></span>Back
  </button>
</template>

<script>
export default {
  name: "BackButton",
  methods: {
    routerBack() {
      this.$router.go(-1);
    }
  }
}
</script>
