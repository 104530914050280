
import { computed, defineComponent, onMounted } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "unread-messages-counter",
  setup() {
    const store = useStore();
    const unreadMessages = computed(() => store.getters["LiveChatModule/getUnreadMessages"]);
    onMounted(() => {
      store.dispatch("LiveChatModule/fetchUnreadMessages");
    });
    return {
      unreadMessages
    };
  }
});
