import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "page d-flex flex-row flex-column-fluid"
}
const _hoisted_2 = {
  id: "kt_wrapper",
  class: "d-flex flex-column flex-row-fluid wrapper"
}
const _hoisted_3 = {
  id: "kt_content",
  class: "content d-flex flex-column flex-column-fluid"
}
const _hoisted_4 = { class: "post d-flex flex-column-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")!
  const _component_KTAside = _resolveComponent("KTAside")!
  const _component_KTHeader = _resolveComponent("KTHeader")!
  const _component_KTToolbar = _resolveComponent("KTToolbar")!
  const _component_KTMobilePageTitle = _resolveComponent("KTMobilePageTitle")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_KTFooter = _resolveComponent("KTFooter")!
  const _component_KTScrollTop = _resolveComponent("KTScrollTop")!
  const _component_KTDrawerMessenger = _resolveComponent("KTDrawerMessenger")!
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!
  const _component_KTCreateApp = _resolveComponent("KTCreateApp")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaderEnabled)
      ? (_openBlock(), _createBlock(_component_KTLoader, {
          key: 0,
          logo: _ctx.loaderLogo
        }, null, 8, ["logo"]))
      : _createCommentVNode("", true),
    (_ctx.currentUser.id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.asideEnabled)
            ? (_openBlock(), _createBlock(_component_KTAside, {
                key: 0,
                lightLogo: _ctx.themeLightLogo,
                darkLogo: _ctx.themeDarkLogo
              }, null, 8, ["lightLogo", "darkLogo"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_KTHeader, { title: _ctx.pageTitle }, null, 8, ["title"]),
            _createElementVNode("div", _hoisted_3, [
              (_ctx.subheaderDisplay)
                ? (_openBlock(), _createBlock(_component_KTToolbar, {
                    key: 0,
                    breadcrumbs: _ctx.breadcrumbs,
                    title: _ctx.pageTitle
                  }, null, 8, ["breadcrumbs", "title"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: _normalizeClass({
              'container-fluid': _ctx.contentWidthFluid,
              container: !_ctx.contentWidthFluid
            })
                }, [
                  (_ctx.subheaderDisplay)
                    ? (_openBlock(), _createBlock(_component_KTMobilePageTitle, {
                        key: 0,
                        breadcrumbs: _ctx.breadcrumbs,
                        title: _ctx.pageTitle
                      }, null, 8, ["breadcrumbs", "title"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_router_view)
                ], 2)
              ])
            ]),
            _createVNode(_component_KTFooter)
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_KTScrollTop),
    _createVNode(_component_KTDrawerMessenger),
    _createVNode(_component_KTUserMenu),
    _createVNode(_component_KTCreateApp)
  ], 64))
}